.pdf-container {
    display: flex;
    justify-content: center;  /* Центрирование по горизонтали */
    align-items: center;      /* Центрирование по вертикали */
    flex-direction: column;   /* Выравнивание страниц PDF в колонку */
    height: 100%;            /* Высота контейнера — вся высота экрана */
}

.react-pdf__Page {
    margin-bottom: 10px;      /* Отступ между страницами */
}