.cards{
    padding-top: 50px;
    max-width: 800px;
    text-align: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    //align-items: center;

}

.cardElement, .cardElementRight{
    margin-bottom: 20px;
    background-color: #eff0f2;;
    border-radius: 10px;
    //max-height: 100px;
    height: 100%;
    width: 100%;
    max-width: 50%;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.cardElementRight{
    transform: translate(100%);
}



.cardText{
    font-family: "Exo 2";
    margin: 10px;
}

