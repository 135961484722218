.input {
    font-family: "Exo 2";
    font-weight: 200;
    font-size: 14px;
    color: #fff;
    background-color: rgb(28,28,30);
    box-shadow: 0 0 .4vw rgba(0,0,0,0.5), 0 0 0 .15vw transparent;
    border-radius: 25px;
    border: 10px;
    outline: none;
    padding: 1vw;
    max-width: 300px;
    transition: .4s;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right : 15px;


}

.input:hover {
    box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.186);
}

.input:focus {
    box-shadow: 0 0 0 .15vw skyblue;
}