.ItemImage{
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    background-color: white;
    height: 140px;
    width: 140px;
    border-radius: 20%;
}

.Items {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    transition: .4s;
}

.ItemCommentsElement{
    //margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    background-color: gray;
    border-radius: 100px;
    height: 200px;
    margin-bottom: 20px;
    overflow-y: scroll;
    scrollbar-width: thin;

}

.ItemName, .ItemPrice, .ItemNameMobile, .ItemPriceMobile  {
    text-align: center;
    font-family: 'Exo 2';
    font-size: 24px;
    text-align: left;
}

.ItemNameMobile, .ItemPriceMobile{
    text-align: center;
    align-items: center;
    width: 500%;
    height: 20px;
}

.ItemPrice{
    text-align: center;
}


.ItemTable{
    width: 100%;
    border-spacing:0px;
    height: auto;
    table-layout: fixed;
}

.ItemTableMobile{
    border-spacing:0px;
    height: auto;
    table-layout: fixed;
}





.ImageTd{
    text-align: center;
}


.Tile, .TileLeft {
    position: relative;
    overflow-x:auto;
    padding: 14px;
    margin-bottom: 20px;
    text-align: center;
    background-color: #eff0f2;
    border-radius: 100px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.Tile:hover, .Tile:focus, .Tile:active {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
}

.TileLeft:hover, .TileLeft:focus, .TileLeft:active {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
}

