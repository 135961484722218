@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.App {
  text-align: center;
}

.StickyTop{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(255,255,255,0.3);
  backdrop-filter: blur(10px);
}

.Logo-holder, .Rot-img{

  text-align: center;
  width: fit-content;
  padding-top: 15px;
  padding-bottom: 11px;
}

.Game-link, .Game-link-child, .Portfolio-image{
  link: true;
  //text-decoration: none;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  text-decoration-color: rgb(255, 15, 0);
  color: #FF0F00;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.Game-link:hover, .Game-link:focus, .Game-link:active,
.Game-link-child:hover, .Game-link-child:focus, .Game-link-child:active,
.Portfolio-image:hover
{
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.Game-link-child{
  text-decoration-color: rgb(255, 81, 70);
  color: #ff4d42;
}

.Portfolio-image{
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  width: 646px;
  height: 190px;
}



.App-header {
  padding: 45px;
  font-family: "Exo 2";

}

.Hr{
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(255,255,255,0.3);
}

@media (prefers-reduced-motion: no-preference) {
  .Rot-img {
    animation: App-logo-spin infinite 1s linear;
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}







