@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.laraItem, .laraItem0, .laraItem1{
    border-radius: 10px;
    background-color: #e8e8e8;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 600px;
    max-height: 120%;
    padding: 10px;
    margin-top: 50px;
    font-family: "Press Start 2P";
    object-fit: cover;
    overflow: hidden;
    //clip-path: inset(100% 100% 100% 100% round 10px);

}

@media (max-width: 700px) {
    .laraItem, .laraItem0, .laraItem1 {
        width: 90%;
    }
}


.laraItem0{
    background-color: #fffeb0;
}

.laraItem1{
    background-color: #b3ffb0;
}

.laraImg{

    margin-bottom: -3%;
    margin-Right: -3%;
    margin-left: -3%;

    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    width: 105%;

    height: 200px;
}

.laraDesc{
    font-weight: normal;
    font-size: 14px;
    color: #5d5d5d;
}